import * as React from "react"
import { Link } from "gatsby"
import { FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h2 className="mt-0">
        <Link to="/">{title.toLowerCase()}</Link>
      </h2>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title.toLowerCase()}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <hr />
      <footer>
        <div>© {new Date().getFullYear()}</div>
        <div>
          <a href="mailto:hello@colemanrollins.io">hello@colemanrollins.io</a>
        </div>
        <div className="icons">
          <a href="https://twitter.com/Kole_MANN" target="_blank">
            <FaTwitter />
          </a>
          <a href="https://github.com/jewbetcha" target="_blank">
            <FaGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/coleman-rollins-87384556"
            target="_blank"
          >
            <FaLinkedin />
          </a>
        </div>
      </footer>
    </div>
  )
}

export default Layout
